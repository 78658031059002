import React, { useContext, useEffect } from "react";
import { useState } from "react";
import {
  // getTwitchAuthenticated,
  saveTwitchChannel,
  searchTwitchChannels,
} from "../api";
import useDebounce from "./useDebounce";
import { useSubscription } from "./useSubscription";

interface ITwitchState {
  broadcasterId: string;
  broadcasterDisplayName: string;
  authenticated: boolean;
}

interface ITwitchContext {
  twitchState: ITwitchState | undefined;
  setTwitchState: (state: ITwitchState) => void;
  searchResults: Array<{ name: string; id: string }> | undefined;
  setSearchResults: (state: Array<{ name: string; id: string }>) => void;
  searchString: string;
  setSearchString: (state: string) => void;
}
const TwitchContext = React.createContext({} as ITwitchContext);

export const TwitchProvider = (props: any) => {
  // just a set state you can use in any component
  const [twitchState, setTwitchState] = useState<ITwitchState | undefined>();
  const [searchResults, setSearchResults] = useState<
    Array<{ name: string; id: string }> | undefined
  >();
  const [searchString, setSearchString] = useState<string>("");

  return (
    <TwitchContext.Provider
      value={{
        twitchState,
        setTwitchState,
        searchResults,
        setSearchResults,
        searchString,
        setSearchString,
      }}
    >
      {props.children}
    </TwitchContext.Provider>
  );
};

export const useTwitchContext = () => {
  const {
    twitchState,
    setTwitchState,
    searchResults,
    setSearchResults,
    searchString,
    setSearchString,
  } = useContext(TwitchContext);

  const selectTwitchChannel = (
    broadcasterId: string,
    broadcasterDisplayName: string
  ) => {
    setTwitchState({
      broadcasterId,
      broadcasterDisplayName,
      authenticated: true,
    });
  };

  const onSaveTwitchChannel = async (
    broadcasterId: string,
    broadcasterDisplayName: string,
    subscriptionId: string,
    subscriptionConfigToken: string
  ) => {
    await saveTwitchChannel(
      subscriptionId,
      subscriptionConfigToken,
      broadcasterId,
      broadcasterDisplayName
    );
  };
  const { subscriptionId, subscriptionConfigToken } = useSubscription();

  // debounce searching for twitch channels
  const debouncedSearchString = useDebounce(searchString, 500);
  useEffect(() => {
    if (debouncedSearchString && subscriptionId && subscriptionConfigToken) {
      searchTwitchChannels(
        debouncedSearchString,
        subscriptionId,
        subscriptionConfigToken
      ).then((response) => {
        response.searchResults.length > 0
          ? setSearchResults(response.searchResults)
          : setSearchResults([]);
      });
      return;
    }
  }, [
    debouncedSearchString,
    setSearchResults,
    subscriptionConfigToken,
    subscriptionId,
  ]);

  // useEffect(() => {
  //   subscriptionId &&
  //     subscriptionConfigToken &&
  //     getTwitchAuthenticated(subscriptionId, subscriptionConfigToken).then(
  //       ({ authenticated, channel }) => {
  //         if (channel && authenticated) {
  //           setTwitchState({
  //             broadcasterId: channel?.broadcasterId || "",
  //             broadcasterDisplayName: channel?.broadcasterDisplayName || "",
  //             authenticated,
  //           });
  //         }
  //       }
  //     );
  // }, [setTwitchState, subscriptionConfigToken, subscriptionId]);

  return {
    searchResults,
    searchString,
    setSearchString,
    twitchState,
    onSaveTwitchChannel,
    selectTwitchChannel,
  };
};
