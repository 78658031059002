import React, { useContext } from "react";
import { useState } from "react";
import { saveYoutubeChannel } from "../api";

interface IYoutubeState {
  channelName: string;
  channelId: string;
}

interface IYoutubeContext {
  youtubeState: IYoutubeState | undefined;
  setYoutubeState: (state: IYoutubeState) => void;
}
const YoutubeContext = React.createContext({} as IYoutubeContext);

export const YoutubeProvider = (props: any) => {
  // just a set state you can use in any component
  const [youtubeState, setYoutubeState] = useState<IYoutubeState | undefined>();
  return (
    <YoutubeContext.Provider value={{ youtubeState, setYoutubeState }}>
      {props.children}
    </YoutubeContext.Provider>
  );
};

export const useYoutubeContext = () => {
  const { youtubeState, setYoutubeState } = useContext(YoutubeContext);
  const [youtubeChannelNameTextInput, setYoutubeChannelNameTextInput] =
    useState("");

  const onYoutubeAdded = async (
    channelName: string,
    subscriptionId: string,
    subscriptionConfigToken: string
  ) => {
    const response = await saveYoutubeChannel(
      subscriptionId,
      subscriptionConfigToken,
      channelName
    );

    setYoutubeState({
      channelName,
      channelId: response.youtubeChannel.channelId,
    });
  };

  return {
    youtubeChannelNameTextInput,
    setYoutubeChannelNameTextInput,
    youtubeState,
    onYoutubeAdded,
  };
};
