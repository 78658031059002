import React, { useContext, useLayoutEffect } from "react";
import { useState } from "react";
import { getPatreonCampaigns, savePatreonCampaign } from "../api";

interface IPatreonContext {
  patreonCampaignOptions: Array<{ name: string; id: string }>;
  setPatreonCampaignOptions: (
    state: Array<{ name: string; id: string }>
  ) => void;
  selectedPatreonCampaign: string | undefined;
  setSelectedPatreonCampaign: (state: string) => void;
}
const PatreonContext = React.createContext({} as IPatreonContext);

export const PatreonProvider = (props: any) => {
  const [patreonCampaignOptions, setPatreonCampaignOptions] = useState<
    Array<{ name: string; id: string }>
  >([]);
  const [selectedPatreonCampaign, setSelectedPatreonCampaign] = useState<
    string | undefined
  >();
  // just a set state you can use in any component
  return (
    <PatreonContext.Provider
      value={{
        patreonCampaignOptions,
        setPatreonCampaignOptions,
        selectedPatreonCampaign,
        setSelectedPatreonCampaign,
      }}
    >
      {props.children}
    </PatreonContext.Provider>
  );
};

export const usePatreonContext = (
  subscriptionId: string | null | undefined,
  subscriptionConfigToken: string | null | undefined
) => {
  const {
    patreonCampaignOptions,
    setPatreonCampaignOptions,
    selectedPatreonCampaign,
    setSelectedPatreonCampaign,
  } = useContext(PatreonContext);

  const savePatreon = async (
    subscriptionId: string,
    subscriptionConfigToken: string
  ) => {
    selectedPatreonCampaign &&
      (await savePatreonCampaign(
        subscriptionId,
        subscriptionConfigToken,
        selectedPatreonCampaign
      ));
  };

  useLayoutEffect(() => {
    subscriptionId &&
      subscriptionConfigToken &&
      getPatreonCampaigns(subscriptionId, subscriptionConfigToken).then(
        ({ campaigns }) => {
          setPatreonCampaignOptions(campaigns);
        }
      );
  }, [subscriptionId, subscriptionConfigToken, setPatreonCampaignOptions]);

  return {
    patreonCampaignOptions,
    selectedPatreonCampaign,
    setSelectedPatreonCampaign,
    savePatreon,
  };
};
