const strings = {
    youtube: {
        settingsHeader: 'YouTube Metrics',
        settingsDetails: 'Keep track of the subscriber count, video count, and view counts for a YouTube channel on Vestaboard.',
        defaultCTA: "Like and Subscribe"
    },
    facebook: {
        settingsHeader: 'Facebook Metrics',
        settingsDetails: 'Keep track of the number of followers and likes of your Facebook business page on Vestaboard.',
        defaultCTA: 'Like and Follow'
    },
    all: {
        settingsHeader: 'Social Counter',
        settingsDetails: 'Send a count of your subscribers and followers to your Vestaboard from various social media platforms.',
        defaultCTA: 'Follow @Vestaboard'
    }
}


export const getSettingsStrings = (provider?: string) => {
    switch (provider) {
        case 'youtube':
            return strings.youtube
        case 'facebook':
            return strings.facebook
        default:
            return strings.all
    }
}