import { useEffect, useState } from "react";
import { getSubscriptionStateParam } from "../api";
import React, { useContext } from "react";



interface IOAuthContext {
  stateParam: string
  setStateParam: (state: string) => void
  fetchingStateParam: boolean;
  setFetchingStateParam: (fetching: boolean) => void
}

const OAuthStateContext = React.createContext({} as IOAuthContext);

export const OAuthStateProvider = (props: {
  subscriptionId?: string | null | undefined,
  subscriptionConfigToken?: string | null | undefined
  children: any
}) => {
  // just a set state you can use in any component
  const [stateParam, setStateParam] = useState<string>('');
  const [fetchingStateParam, setFetchingStateParam] = useState<boolean>(false);

  return (
    <OAuthStateContext.Provider value={{ stateParam, setStateParam, fetchingStateParam, setFetchingStateParam }}>
      {props.children}
    </OAuthStateContext.Provider>
  );
};

export const useOAuthState = (
  subscriptionId?: string | null | undefined,
  subscriptionConfigToken?: string | null | undefined,
  platform?: string | null | undefined,
) => {
  const { stateParam, setStateParam, fetchingStateParam, setFetchingStateParam } = useContext(OAuthStateContext);
  useEffect(() => {
    if (!fetchingStateParam && !stateParam) {
      setFetchingStateParam(true)
      subscriptionId &&
        subscriptionConfigToken && !stateParam && platform &&
        getSubscriptionStateParam(subscriptionId, subscriptionConfigToken, platform).then(
          ({ state }) => {
            setStateParam(state);
            setFetchingStateParam(false)
          }
        ).catch(() => {
          setFetchingStateParam(false)
        })
    }
  }, [subscriptionId, subscriptionConfigToken, fetchingStateParam, setFetchingStateParam, stateParam, setStateParam, platform]);  

  return {
    stateParam,
    fetchingStateParam
  };
};
