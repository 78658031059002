import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import {
  Body,
  Small,
  SubTitle,
  SubTitle2,
} from "@vestaboard/installables/lib/components/Typography";
import {
  ActionButton,
  BoardPreview,
  Button,
  Cadence,
  CadencePicker,
  DayOfWeek,
  Divider,
  ISwatchValue,
  Input,
  Interval,
  Modal,
  NotificationBanner,
  Spacer,
  SwatchPicker,
  ToggleButton,
  parseTimeString,
  useToasts,
} from "@vestaboard/installables";
import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DataProviders,
  IConnectedAccounts,
  checkSetupComplete,
  displayNow,
  saveSubscription,
} from "../api";
import { useSubscription } from "../hooks/useSubscription";
import { FacebookModal } from "../components/FacebookModal";
import { YoutubeModal } from "../components/YoutubeModal";
import { ConnectAccountSection } from "../components/ConnectAccountSection";
import { TwitchModal } from "../components/TwitchModal";
import { PatreonModal } from "../components/PatreonModal";
import { useParams } from "react-router-dom";
import { getSettingsStrings } from "../dataProviderContstants";
import { useIsQuietHours } from "../hooks/useQuietHours";

const useStyles = makeStyles({
  section: {
    width: "100%",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    paddingTop: 24,
    marginLeft: 16,
    marginRight: 16,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    height: "100%",
    padding: 16,
    paddingTop: 32,
    paddingBottom: 32,
    backgroundColor: "#25282a",
  },
  descriptionContainer: {
    textAlign: "left",
    width: "100%",
    maxWidth: 600,
  },
});

export const Settings = () => {
  const classes = useStyles();
  const { singledataprovider } = useParams<{ singledataprovider?: string }>();
  const strings = getSettingsStrings(singledataprovider);

  const [openPreview, setOpenPreview] = useState(false);
  const [updateOn, setUpdateOn] = useState("repeating_cadence");

  const [openModal, setOpenModal] = useState<DataProviders | undefined>();
  const [messageColor, setMessageColor] = useState<ISwatchValue>(69); // default white
  const [messageHeader, setMessageHeader] = useState<string>(
    strings.defaultCTA
  );
  const [connectedAccounts, setConnectedAccounts] =
    useState<IConnectedAccounts>({});

  const [setupComplete, setSetupComplete] = useState(false);

  const {
    boardId,
    subscriptionConfigToken,
    subscriptionId,
    loading,
    boardTitle,
  } = useSubscription();

  const { formattedQuietHours, isQuietHours } = useIsQuietHours({
    subscriptionId,
  });

  const [cadence, setCadence] = useState<Cadence | undefined>({
    interval: Interval.Hour,
    startAtTime: parseTimeString("11:55"),
    endAtTime: parseTimeString("12:55"),
    daysOfTheWeek: [
      DayOfWeek.Monday,
      DayOfWeek.Tuesday,
      DayOfWeek.Wednesday,
      DayOfWeek.Thursday,
      DayOfWeek.Friday,
    ],
  });

  const { addToast } = useToasts();

  const fetchSettings = useCallback(async () => {
    boardId &&
      subscriptionConfigToken &&
      subscriptionId &&
      (await checkSetupComplete(
        boardId,
        subscriptionId,
        subscriptionConfigToken
      ).then(
        ({
          setupComplete,
          connectedAccounts,
          cadence,
          messageHeader,
          messageColor,
          updateOnNewFollows,
          promptSetup,
        }) => {
          setConnectedAccounts(connectedAccounts);

          // @ts-ignore
          setCadence(cadence);
          setSetupComplete(setupComplete);
          messageColor && setMessageColor(messageColor);
          messageHeader && setMessageHeader(messageHeader);
          promptSetup && setOpenModal(promptSetup);
          updateOnNewFollows
            ? setUpdateOn("new_follows")
            : cadence?.interval && setUpdateOn("repeating_cadence");
        }
      ));
  }, [boardId, subscriptionId, subscriptionConfigToken]);

  useEffect(() => {
    fetchSettings();
  }, [boardId, subscriptionId, subscriptionConfigToken, fetchSettings]);

  useLayoutEffect(() => {
    !setupComplete &&
      !loading &&
      subscriptionConfigToken &&
      subscriptionId &&
      saveSubscription({
        subscriptionConfigToken,
        subscriptionId,
        boardId,
        boardTitle,
      }).then(() => {
        fetchSettings();
      });
  }, [
    subscriptionConfigToken,
    subscriptionId,
    setupComplete,
    loading,
    boardId,
    boardTitle,
    fetchSettings,
  ]);

  if (loading || !setupComplete) {
    return (
      <Box className={classes.content}>
        <Box className={classes.descriptionContainer}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.content}>
        <Box className={classes.descriptionContainer}>
          <NotificationBanner
            text={formattedQuietHours}
            visible={isQuietHours}
          />
          <SubTitle>Description</SubTitle>
          <Spacer size={"large"} />
          <Body>{strings.settingsDetails}</Body>
          <Spacer size={"medium"} />
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <ActionButton
              onClick={() => {
                setOpenPreview(true);
              }}
            >
              Preview
            </ActionButton>
            <div style={{ width: 16 }}></div>
            <ActionButton
              disabled={
                Object.keys(connectedAccounts).length === 0 || !setupComplete
              }
              onClick={async () => {
                if (isQuietHours) {
                  addToast(formattedQuietHours, {
                    appearance: "error",
                  });
                  return;
                }

                subscriptionConfigToken &&
                  subscriptionId &&
                  (await displayNow(
                    boardId,
                    subscriptionId,
                    subscriptionConfigToken
                  ));
              }}
            >
              Display Now
            </ActionButton>
          </Box>
          <Spacer size={"large"} />
          <Divider />
          <Spacer size={"medium"} />
          <SubTitle>{strings.settingsHeader}</SubTitle>
          <Spacer size={"medium"} />
        </Box>
        {/* TODO MODALS INSTEAD OF SECTIONS */}
        <ConnectAccountSection
          connectedAccounts={connectedAccounts}
          dataProvider={DataProviders.facebook}
          title={"Connect Facebook Business Page"}
          fetchSettings={fetchSettings}
          setOpenModal={setOpenModal}
          serviceName={"Facebook"}
        />
        <ConnectAccountSection
          connectedAccounts={connectedAccounts}
          dataProvider={DataProviders.instagram}
          title={"Connect Instagram Business"}
          detailText={
            "Connect your Instagram account tied to your Facebook Page"
          }
          fetchSettings={fetchSettings}
          setOpenModal={() => {
            // Facebook and Instagram use the same modal
            setOpenModal(DataProviders.facebook);
          }}
          serviceName={"Instagram"}
        />

        <ConnectAccountSection
          connectedAccounts={connectedAccounts}
          dataProvider={DataProviders.youtube}
          title={"Connect YouTube Channel"}
          fetchSettings={fetchSettings}
          setOpenModal={setOpenModal}
          serviceName={"YouTube"}
        />
        <ConnectAccountSection
          connectedAccounts={connectedAccounts}
          dataProvider={DataProviders.twitch}
          title={"Connect Twitch Channel"}
          fetchSettings={fetchSettings}
          setOpenModal={setOpenModal}
          serviceName={"Twitch"}
        />

        <ConnectAccountSection
          connectedAccounts={connectedAccounts}
          dataProvider={DataProviders.patreon}
          title={"Connect Patreon Campaign"}
          fetchSettings={fetchSettings}
          setOpenModal={setOpenModal}
          serviceName={"Patreon"}
        />

        <Box className={classes.section}>
          <Divider />
          <Spacer size={"medium"} />
          <SubTitle2>Header</SubTitle2>
          <Spacer size={"medium"} />
          <Input
            placeholder={strings.defaultCTA}
            onValueChange={(text) =>
              text.length <= 22 && setMessageHeader(text)
            }
            value={messageHeader}
            max="22"
          ></Input>
          <Spacer size={"medium"} />
          {!singledataprovider && (
            <>
              <SubTitle2>Line Separator Color</SubTitle2>
              <Spacer size={"medium"} />

              <SwatchPicker
                value={messageColor}
                setValue={setMessageColor}
                hideEditTools={true}
                spaceEvenly={true}
                endPaintMode={() => {}}
                isPaintMode={false}
                rainbowSwatch={true}
              />
              <Spacer size={"medium"} />
              <Box style={{ textAlign: "center" }}>
                <Small>
                  Choose the color of the line separator shown below the header
                  text.
                </Small>
              </Box>
            </>
          )}
        </Box>
        <Box className={classes.section}>
          <Divider />
          <Spacer size={"medium"} />
          <SubTitle2>Frequency</SubTitle2>
          <Spacer size={"medium"} />
          {singledataprovider === "youtube" ||
          singledataprovider === "facebook" ? (
            <></>
          ) : (
            <>
              <ToggleButton
                condenseOnMobile={false}
                options={[
                  {
                    value: "new_follows",
                    key: "New Follows",
                  },
                  {
                    value: "repeating_cadence",
                    key: "Repeating Cadence",
                  },
                ]}
                onToggle={setUpdateOn}
                value={updateOn}
              />
              <Spacer size={"medium"} />
            </>
          )}
          {updateOn === "repeating_cadence" && (
            <CadencePicker
              allowWeekdaySelection
              defaultDays={[
                DayOfWeek.Monday,
                DayOfWeek.Tuesday,
                DayOfWeek.Wednesday,
                DayOfWeek.Thursday,
                DayOfWeek.Friday,
                DayOfWeek.Saturday,
                DayOfWeek.Sunday,
              ]}
              intervals={[
                // Interval.Minutes_15,
                // Interval.Minutes_20,
                Interval.Minutes_30,
                Interval.Hour,
                Interval.Hour_4,
                Interval.Day,
                Interval.Week,
              ]}
              cadence={cadence}
              onChange={(cadence) => {
                setCadence(cadence);
              }}
            />
          )}
        </Box>
        <Spacer size={"medium"} />
        <Box className={classes.section}>
          <Spacer size={"large"} />
          <Button
            buttonType="white"
            onClick={async () => {
              if (subscriptionId && subscriptionConfigToken) {
                const dataProviders = Object.keys(
                  connectedAccounts
                ) as Array<DataProviders>;
                await saveSubscription({
                  subscriptionConfigToken,
                  subscriptionId,
                  boardId,
                  boardTitle,
                  dataProviders,
                  updateOnNewFollows: updateOn === "new_follows",
                  cadence:
                    updateOn === "new_follows"
                      ? {
                          interval: Interval.Minute_1,
                          startAtTime: parseTimeString("12:00"),
                          endAtTime: parseTimeString("11:59"),
                          daysOfTheWeek: [
                            DayOfWeek.Monday,
                            DayOfWeek.Tuesday,
                            DayOfWeek.Wednesday,
                            DayOfWeek.Thursday,
                            DayOfWeek.Friday,
                            DayOfWeek.Saturday,
                            DayOfWeek.Sunday,
                          ],
                        }
                      : cadence,
                  messageHeader,
                  messageColor,
                });
                addToast("Channel updated", { appearance: "success" });
              }
            }}
          >
            {"Save Changes"}
          </Button>
        </Box>
        <Spacer size={"large"} />
      </Box>
      {/* Conditional render to avoid fetching on load */}
      {openModal === DataProviders.facebook && (
        <FacebookModal
          key={`${openModal}-facebook`}
          visible={openModal === DataProviders.facebook}
          onSuccess={async () => await fetchSettings()}
          onClose={() => {
            setOpenModal(undefined);
          }}
        />
      )}
      <YoutubeModal
        key={`${openModal}-youtube`}
        visible={openModal === DataProviders.youtube}
        onSuccess={async () => await fetchSettings()}
        onClose={() => {
          setOpenModal(undefined);
        }}
      />

      <TwitchModal
        key={`${openModal}-twitch`}
        visible={openModal === DataProviders.twitch}
        onSuccess={async () => await fetchSettings()}
        onClose={() => {
          setOpenModal(undefined);
        }}
        setOpenModal={setOpenModal}
      />

      <PatreonModal
        key={`${openModal}-patreon`}
        visible={openModal === DataProviders.patreon}
        onSuccess={async () => await fetchSettings()}
        onClose={() => {
          setOpenModal(undefined);
        }}
        setOpenModal={setOpenModal}
      />

      <Modal
        visible={openPreview}
        transparent
        fullScreenMobile
        onClose={() => {
          setOpenPreview(false);
        }}
      >
        <Box
          style={{
            padding: 16,
          }}
        >
          <BoardPreview
            isFavorite={false}
            characters={
              singledataprovider === "facebook"
                ? [
                    [
                      0, 0, 0, 12, 9, 11, 5, 0, 1, 14, 4, 0, 6, 15, 12, 12, 15,
                      23, 0, 0, 0, 0,
                    ],
                    [
                      0, 0, 0, 0, 0, 0, 22, 5, 19, 20, 1, 2, 15, 1, 18, 4, 0, 0,
                      0, 0, 0, 0,
                    ],
                    [
                      67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67,
                      67, 67, 67, 67, 67, 67, 67, 67,
                    ],
                    [
                      12, 9, 11, 5, 19, 50, 0, 0, 0, 0, 0, 0, 0, 29, 55, 36, 36,
                      36, 55, 36, 28, 30,
                    ],
                    [
                      6, 15, 12, 12, 15, 23, 5, 18, 19, 50, 0, 0, 0, 28, 55, 27,
                      28, 29, 55, 27, 36, 35,
                    ],
                    [
                      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                      0, 0, 0,
                    ],
                  ]
                : singledataprovider === "youtube"
                ? [
                    [
                      0, 0, 12, 9, 11, 5, 0, 1, 14, 4, 0, 19, 21, 2, 19, 3, 18,
                      9, 2, 5, 0, 0,
                    ],
                    [
                      0, 0, 0, 0, 0, 38, 22, 5, 19, 20, 1, 2, 15, 1, 18, 4, 0,
                      0, 0, 0, 0, 0,
                    ],
                    [
                      63, 63, 63, 63, 63, 63, 63, 63, 63, 63, 63, 63, 63, 63,
                      63, 63, 63, 63, 63, 63, 63, 63,
                    ],
                    [
                      19, 21, 2, 19, 3, 18, 9, 2, 5, 18, 19, 50, 0, 0, 0, 0, 0,
                      28, 56, 30, 34, 11,
                    ],
                    [
                      22, 9, 5, 23, 19, 50, 0, 0, 0, 0, 0, 0, 0, 27, 55, 36, 28,
                      36, 55, 32, 30, 36,
                    ],
                    [
                      22, 9, 4, 5, 15, 0, 3, 15, 21, 14, 20, 50, 0, 0, 0, 0, 0,
                      0, 0, 0, 31, 28,
                    ],
                  ]
                : [
                    [
                      0, 0, 6, 15, 12, 12, 15, 23, 0, 38, 22, 5, 19, 20, 1, 2,
                      15, 1, 18, 4, 0, 0,
                    ],
                    [
                      69, 69, 69, 69, 69, 69, 69, 69, 69, 69, 69, 69, 69, 69,
                      69, 69, 69, 69, 69, 69, 69, 69,
                    ],
                    [
                      25, 15, 21, 20, 21, 2, 5, 50, 0, 0, 0, 0, 0, 0, 0, 27, 28,
                      32, 30, 36, 36, 36,
                    ],
                    [
                      9, 14, 19, 20, 1, 7, 18, 1, 13, 50, 0, 0, 0, 0, 0, 0, 30,
                      34, 27, 36, 28, 28,
                    ],
                    [
                      6, 1, 3, 5, 2, 15, 15, 11, 50, 0, 0, 0, 0, 0, 0, 0, 28,
                      28, 29, 30, 36, 28,
                    ],
                    [
                      20, 23, 9, 20, 3, 8, 50, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 28,
                      29, 36, 29, 30,
                    ],
                    // TIKOTK preview
                    // [
                    //   20, 9, 11, 20, 15, 11, 50, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 27, 28,
                    //   36, 36, 31,
                    // ],
                    // 22 length array of number 0
                    // [
                    //   0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    //   0,
                    // ],
                  ]
            }
          />
        </Box>
      </Modal>
    </>
  );
};
