import { Cadence, ISwatchValue } from "@vestaboard/installables";
import axios from "axios";

export const API_URL = "https://platform.vestaboard.com";

export const getSubscriptionCreds = async (subscriptionConfigToken: string) => {
  const response = await axios.get(
    `${API_URL}/v2.0/subscription-configuration`,
    {
      headers: {
        "X-Vestaboard-Subscription-Configuration-Token":
          subscriptionConfigToken,
      },
    }
  );

  const data = response.data as any;
  const subscriptionId = data["subscription"]["id"];
  const apiKey = data["subscription"]["installation"]["apiCredential"]["key"];
  const apiSecret =
    data["subscription"]["installation"]["apiCredential"]["secret"];

  return {
    subscriptionId,
    apiKey,
    apiSecret,
  };
};

export const getQuietHoursData = async (subscriptionId: string) => {
  const resp = await axios.get(
    `/api/subscription/${subscriptionId}/quiethours`,
    {}
  );
  return resp.data as {
    formattedQuietHours: string;
    isQuietHours: boolean;
  };
};

export const getSubscriptionInfo = async (subscriptionConfigToken: string) => {
  const response = await axios.get(
    `${API_URL}/v2.0/subscription-configuration`,
    {
      headers: {
        "X-Vestaboard-Subscription-Configuration-Token":
          subscriptionConfigToken,
      },
    }
  );

  return response.data as any;
};

export interface IConnectedAccount {
  accountName: string;
}

export interface IConnectedAccounts {
  facebook?: IConnectedAccount;
  instagram?: IConnectedAccount;
  youtube?: IConnectedAccount;
  twitch?: IConnectedAccount;
  patreon?: IConnectedAccount;
}

export const checkSetupComplete = async (
  boardId: string,
  subscriptionId: string,
  subscriptionConfigToken: string
) => {
  const response = await axios.post("/api/checksetup", {
    boardId,
    subscriptionId,
    subscriptionConfigToken,
  });
  const data = response?.data as {
    setupComplete: boolean;
    dataProviders: Array<string>;
    cadence: Cadence;
    connectedAccounts: IConnectedAccounts;
    messageHeader?: string;
    messageColor?: ISwatchValue;
    updateOnNewFollows?: boolean;
    promptSetup?: DataProviders;
  };
  return data;
};

export enum DataProviders {
  facebook = "facebook",
  instagram = "instagram",
  youtube = "youtube",
  twitch = "twitch",
  patreon = "patreon",
}

export interface ISubscriptionReq {
  subscriptionId: string;
  boardId: string;
  boardTitle: string;
  subscriptionConfigToken: string;
  dataProviders?: Array<DataProviders>;
  cadence?: Cadence;
  messageHeader?: string;
  messageColor?: ISwatchValue;
  updateOnNewFollows?: boolean;
}

export const saveSubscription = async (subscription: ISubscriptionReq) => {
  const response = await axios.post("/api/subscription", {
    ...subscription,
  });
  const data = response?.data as {
    subscription: {
      subscriptionId: string;
      subscriptionConfigToken: string;
      boardId: string;
      boardTitle: string;
      cadence: Cadence;
      messageHeader?: string;
      messageColor?: ISwatchValue;
    };
  };
  return data;
};

export const displayNow = async (
  boardId: string,
  subscriptionId: string,
  subscriptionConfigToken: string
) => {
  await axios.post("/api/display-now", {
    subscriptionConfigToken,
    subscriptionId,
    boardId,
  });
};

interface IFacebookPagesResponse {
  data: {
    pages: Array<{ id: string; name: string }>;
    userId: string;
  };
}

export const getFacebookPages = async (
  subscriptionId: string,
  subscriptionConfigToken: string
) => {
  const response = (await axios.get(
    `/api/facebookpages?subscriptionId=${subscriptionId}`,
    {
      headers: {
        "X-Vestaboard-Subscription-Config-Token": subscriptionConfigToken,
      },
    }
  )) as IFacebookPagesResponse;
  return { pages: response?.data?.pages, userId: response?.data?.userId };
};

export const getInstagramAccounts = async (
  subscriptionId: string,
  subscriptionConfigToken: string,
  selectedPageId: string
) => {
  const response = (await axios.get(
    `/api/instagram-accounts?selectedPageId=${selectedPageId}&subscriptionId=${subscriptionId}`,
    {
      headers: {
        "X-Vestaboard-Subscription-Config-Token": subscriptionConfigToken,
      },
    }
  )) as IFacebookPagesResponse;
  return response?.data?.pages;
};

export const saveFacebookPage = async (
  subscriptionId: string,
  subscriptionConfigToken: string,
  pageId: string,
  instagramId: string
) => {
  const response = await axios.post(
    "/api/facebookpage",
    {
      subscriptionId,
      subscriptionConfigToken,
      pageId,
      instagramId,
    },
    {
      headers: {
        "X-Vestaboard-Subscription-Config-Token": subscriptionConfigToken,
      },
    }
  );
  return response?.data as {
    facebookpage: {
      pageId: string;
      pageName: string;
      fanCount: number;
      followersCount: number;
    };
  };
};

export const facebookLoginWebApp = async (
  userAccessToken: string,
  subscriptionId: string,
  subscriptionConfigToken: string
) => {
  const response = await axios.post(
    "/api/facebook/webapp/auth",
    {
      userAccessToken,
      subscriptionId,
      subscriptionConfigToken,
    },
    {
      headers: {
        "X-Vestaboard-Subscription-Config-Token": subscriptionConfigToken,
      },
    }
  );
  return response?.data as {
    redirectUrl: string;
  };
};

export const saveYoutubeChannel = async (
  subscriptionId: string,
  subscriptionConfigToken: string,
  channelName: string
) => {
  const response = await axios.post("/api/youtubechannel", {
    subscriptionId,
    subscriptionConfigToken,
    channelName,
  });
  return response?.data as {
    youtubeChannel: {
      channelId: string;
      channelName: string;
      viewCount: string;
      subscriberCount: string;
      hiddenSubscriberCount: boolean;
      videoCount: string;
    };
  };
};

export const searchTwitchChannels = async (
  channelName: string,
  subscriptionId: string,
  subscriptionConfigToken: string
) => {
  const response = await axios.get(
    `/api/twitch/search?query=${encodeURIComponent(
      channelName
    )}&subscriptionId=${subscriptionId}`,
    {
      headers: {
        "X-Vestaboard-Subscription-Config-Token": subscriptionConfigToken,
      },
    }
  );
  return response?.data as {
    searchResults: Array<{
      id: string;
      name: string;
    }>;
  };
};

export const saveTwitchChannel = async (
  subscriptionId: string,
  subscriptionConfigToken: string,
  broadcasterId: string,
  broadcasterDisplayName: string
) => {
  const response = await axios.post("/api/twitchchannel", {
    subscriptionId,
    subscriptionConfigToken,
    broadcasterId,
    broadcasterDisplayName,
  });
  return response?.data;
};

export const getPatreonCampaigns = async (
  subscriptionId: string,
  subscriptionConfigToken: string
) => {
  const response = await axios.get(
    `/api/patreon/campaigns?subscriptionId=${subscriptionId}`,
    {
      headers: {
        "X-Vestaboard-Subscription-Config-Token": subscriptionConfigToken,
      },
    }
  );
  return response?.data as {
    campaigns: Array<{
      id: string;
      name: string;
      // patron_count: number;
    }>;
  };
};

export const savePatreonCampaign = async (
  subscriptionId: string,
  subscriptionConfigToken: string,
  campaignId: string
) => {
  const response = await axios.post("/api/patreon/campaign", {
    subscriptionId,
    subscriptionConfigToken,
    campaignId,
  });
  return response?.data as {
    patreonCampaign: {
      id: string;
      name: string;
    };
  };
};

export const removeDataProvider = async (
  subscriptionId: string,
  subscriptionConfigToken: string,
  dataProvider: string
) => {
  const response = await axios.post("/api/remove-dataprovider", {
    subscriptionId,
    subscriptionConfigToken,
    dataProvider,
  });
  return response?.data as {
    success: boolean;
  };
};

// use this for oauth flows that need a state argument
export const getSubscriptionStateParam = async (
  subscriptionId: string,
  subscriptionConfigToken: string,
  platform: string
) => {
  const response = await axios.get(
    `/auth/state?subscriptionId=${subscriptionId}&platform=${platform}`,
    {
      headers: {
        "X-Vestaboard-Subscription-Config-Token": subscriptionConfigToken,
      },
    }
  );
  return response?.data as {
    state: string;
  };
};
