import { useLayoutEffect, useState } from "react";
import { getSubscriptionInfo } from "../api";
import { useVestaboardQueryParams } from "@vestaboard/installables";

export const useSubscription = () => {
  const { subscriptionId, subscriptionConfigToken, platform } =
    useVestaboardQueryParams();
  const [title, setTitle] = useState("");
  const [boardId, setBoardId] = useState("");
  const [boardTitle, setBoardTitle] = useState("");

  useLayoutEffect(() => {
    if (subscriptionConfigToken && !boardId) {
      getSubscriptionInfo(subscriptionConfigToken).then(({ subscription }) => {
        if (subscription?.title) {
          // initialize title with user title if they customized their subscription
          setTitle(subscription?.title);
        }
        const boardId = subscription.board[0].id;
        setBoardId(boardId);
        setBoardTitle(subscription.board[0].title);
      });
    }
  }, [subscriptionConfigToken, boardId]);


  return {
    subscriptionId,
    subscriptionConfigToken,
    platform,
    title,
    setTitle,
    boardTitle,
    boardId,
    loading: !boardId,
  };
};
