import {
  Body,
  Button,
  Divider,
  Input,
  Modal,
  Spacer,
  SubTitle2,
  useToasts,
  useVestaboardQueryParams,
} from "@vestaboard/installables";
import { Box } from  "@mui/material";
import { useDisableBodyScroll } from "../hooks/useDisableBackgroundScroll";
import { useTwitchContext } from "../hooks/useTwitchContext";
import { SelectMenu } from "@vestaboard/installables/lib/components/SelectMenu";
import { useOAuthState } from "../hooks/useOAuthContext";
import { DataProviders, removeDataProvider } from "../api";

interface ITwitchModalProps {
  onClose: () => void;
  visible: boolean;
  onSuccess: () => void;
  setOpenModal: (provider: DataProviders) => void;
}

export const TwitchModal = (props: ITwitchModalProps) => {
  const { subscriptionId, subscriptionConfigToken, platform } =
    useVestaboardQueryParams();
  const {
    twitchState,
    searchResults,
    searchString,
    setSearchString,
    onSaveTwitchChannel,
    selectTwitchChannel,
  } = useTwitchContext();

  useDisableBodyScroll(props.visible);

  const { addToast } = useToasts();
  const redirectUri = `https://${window.location.host}/twitch/callback`;
  const {stateParam} = useOAuthState(
    subscriptionId,
    subscriptionConfigToken,
    platform
  );

  return (
    <Modal
      visible={props.visible}
      onClose={async () => {
        !twitchState?.broadcasterId &&
          subscriptionId &&
          subscriptionConfigToken &&
          (await removeDataProvider(
            subscriptionId,
            subscriptionConfigToken,
            DataProviders.twitch
          ));
        props.onClose();
      }}
      fullScreenMobile
    >
      <Box style={{ paddingLeft: 8, paddingRight: 8 }}>
        <SubTitle2>Twitch Channel</SubTitle2>
        <Spacer size={"medium"} />
        <Body>Authorize Twitch</Body>
        <Spacer size={"medium"} />
          <>
            <Input
              label="Search"
              value={searchString}
              onValueChange={setSearchString}
              placeholder="Search Twitch Channels"
            />
            <Spacer size={"medium"} />
            {searchResults && (
              <>
                <Divider />
                <Spacer size={"medium"} />
                <Body>Select a Twitch Channel</Body>
                <SelectMenu
                  options={searchResults}
                  onPress={(id) => {
                    const selectedChannel = searchResults.find(
                      (channel) => channel.id === id
                    );
                    selectedChannel &&
                      selectTwitchChannel(
                        selectedChannel.id,
                        selectedChannel.name
                      );
                  }}
                  selected={
                    twitchState?.broadcasterId
                      ? [twitchState?.broadcasterId]
                      : [""]
                  }
                />
                <Spacer size={"medium"} />
              </>
            )}
            <Button
              buttonType="white"
              disabled={!twitchState?.broadcasterId}
              onClick={async () => {
                subscriptionId &&
                  subscriptionConfigToken &&
                  twitchState?.broadcasterId &&
                  twitchState?.broadcasterDisplayName &&
                  (await onSaveTwitchChannel(
                    twitchState?.broadcasterId,
                    twitchState?.broadcasterDisplayName,
                    subscriptionId,
                    subscriptionConfigToken
                  ));
                await addToast("Twitch channel connected", {
                  appearance: "success",
                });
                await props.onSuccess();
                props.onClose();
              }}
            >
              Save Twitch Channel
            </Button>
          </>
        <Spacer size={"medium"} />
          <Button
            buttonType="white"
            onClick={async () => {
              window.location.href = 
                `https://id.twitch.tv/oauth2/authorize?redirect_uri=${redirectUri}&client_id=oee5o9bynl152uc08ci3r5ukw7f1un&state=${stateParam}&response_type=code&scope=moderator:read:followers`
              
            }}
          >
            Connect Twitch Account
          </Button>
      </Box>
    </Modal>
  );
};
