import { Box } from "@mui/material";
import { makeStyles} from "@mui/styles";

import {
  Body,
  Button,
  Medium,
  Spacer,
  SubTitle2,
  useVestaboardQueryParams,
} from "@vestaboard/installables";
import { DataProviders, IConnectedAccounts, removeDataProvider } from "../api";
import { useParams } from "react-router-dom";

interface IConectAccountProps {
  connectedAccounts: IConnectedAccounts;
  dataProvider: DataProviders;
  title: string;
  detailText?: string;
  fetchSettings: () => void;
  setOpenModal: (provider: DataProviders) => void;
  serviceName: string;
}

const useStyles = makeStyles({
  section: {
    width: "100%",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    paddingTop: 24,
    marginLeft: 16,
    marginRight: 16,
  },
});

export const ConnectAccountSection = (props: IConectAccountProps) => {
  const dataProviders = Object.keys(props.connectedAccounts);
  const connectedAccount = dataProviders.includes(props.dataProvider)
    ? props.connectedAccounts[props.dataProvider]
    : { accountName: undefined };
  const { subscriptionId, subscriptionConfigToken } =
    useVestaboardQueryParams();
  const classes = useStyles();
  const { singledataprovider } = useParams<{ singledataprovider?: string }>();

  if (singledataprovider && singledataprovider !== props.dataProvider) {
    return <></>;
  }

  return (
    <Box className={classes.section}>
      <SubTitle2>{props.title}</SubTitle2>
      <Spacer size={"medium"} />
      {!connectedAccount?.accountName && props.detailText && (
        <>
          <Medium>{props.detailText}</Medium>
          <Spacer size={"medium"} />
        </>
      )}
      {connectedAccount?.accountName && (
        <>
          <Body>Connected to {connectedAccount?.accountName}</Body>
          <Spacer size={"medium"} />
        </>
      )}
      <Button
        buttonType={connectedAccount?.accountName ? "danger" : "outline"}
        onClick={async () => {
          if (
            connectedAccount &&
            connectedAccount?.accountName &&
            subscriptionId &&
            subscriptionConfigToken
          ) {
            await removeDataProvider(
              subscriptionId,
              subscriptionConfigToken,
              props.dataProvider
            );
            await props.fetchSettings();
          } else {
            props.setOpenModal(props.dataProvider);
          }
        }}
      >
        {connectedAccount?.accountName
          ? `Remove ${props.serviceName}`
          : `Connect ${props.serviceName}`}
      </Button>
      <Spacer size={"medium"} />
    </Box>
  );
};
