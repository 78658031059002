import {
  Body,
  Button,
  Divider,
  Modal,
  Spacer,
  SubTitle2,
  useToasts,
  useVestaboardQueryParams,
} from "@vestaboard/installables";
import { Box } from  "@mui/material";
import { useDisableBodyScroll } from "../hooks/useDisableBackgroundScroll";
import { SelectMenu } from "@vestaboard/installables/lib/components/SelectMenu";
import { useOAuthState } from "../hooks/useOAuthContext";
import { DataProviders, removeDataProvider } from "../api";
import { usePatreonContext } from "../hooks/usePatreonContext";

interface IPatreonModalProps {
  onClose: () => void;
  visible: boolean;
  onSuccess: () => void;
  setOpenModal: (provider: DataProviders) => void;
}

export const PatreonModal = (props: IPatreonModalProps) => {
  const { subscriptionId, subscriptionConfigToken, platform } =
    useVestaboardQueryParams();
  const {
    savePatreon,
    patreonCampaignOptions,
    selectedPatreonCampaign,
    setSelectedPatreonCampaign,
  } = usePatreonContext(subscriptionId, subscriptionConfigToken);

  useDisableBodyScroll(props.visible);

  const { addToast } = useToasts();
  const redirectUri = `https://${window.location.host}/patreon/callback`;
  const { stateParam } = useOAuthState(
    subscriptionId,
    subscriptionConfigToken,
    platform
  );

  return (
    <Modal
      visible={props.visible}
      onClose={async () => {
        !selectedPatreonCampaign &&
          subscriptionId &&
          subscriptionConfigToken &&
          (await removeDataProvider(
            subscriptionId,
            subscriptionConfigToken,
            DataProviders.patreon
          ));
        props.onClose();
      }}
      fullScreenMobile
    >
      <Box style={{ paddingLeft: 8, paddingRight: 8 }}>
        <SubTitle2>Patreon Campaign</SubTitle2>
        <Spacer size={"medium"} />
        <Body>Authorize Patreon</Body>
        <Spacer size={"medium"} />
        {patreonCampaignOptions.length > 0 && (
          <>
            <Divider />
            <Spacer size={"medium"} />
            <Body>Select a Patreon Campaign</Body>
            <SelectMenu
              options={patreonCampaignOptions || []}
              onPress={(id) => {
                const selectedCampaign = (patreonCampaignOptions || []).find(
                  (campaign) => campaign.id === id
                );
                selectedCampaign &&
                  setSelectedPatreonCampaign(selectedCampaign.id);
              }}
              selected={
                selectedPatreonCampaign ? [selectedPatreonCampaign] : [""]
              }
            />
            <Spacer size={"medium"} />

            <Button
              buttonType="white"
              disabled={!selectedPatreonCampaign}
              onClick={async () => {
                subscriptionId &&
                  subscriptionConfigToken &&
                  (await savePatreon(subscriptionId, subscriptionConfigToken));
                await addToast("Patreon campaign connected", {
                  appearance: "success",
                });
                await props.onSuccess();
                props.onClose();
              }}
            >
              Save Patreon Campaign
            </Button>
          </>
        )}
        <Spacer size={"medium"} />
        {patreonCampaignOptions.length === 0 && (
          <Button
            buttonType="white"
            onClick={async () => {
              window.location.href = `https://www.patreon.com/oauth2/authorize?redirect_uri=${redirectUri}&client_id=nc6EXdRVhVFlzK-OVuwuOBj1iOjxWGZCEgh_nlmTz2qNTvDgP0HSr3HuHf-cvarC&state=${stateParam}&response_type=code`;
            }}
          >
            Connect Patreon Account
          </Button>
        )}
      </Box>
    </Modal>
  );
};
