import { Button } from "@vestaboard/installables";
import FacebookLogin from "react-facebook-login";
import { facebookLoginWebApp } from "../api";

export const FBButton = (props: {
  subscriptionId?: string | null | undefined;
  subscriptionConfigToken?: string | null | undefined;
  state: string;
}) => {
  const redirectUri = `https://${window.location.host}/facebook/callback`;
  console.log(redirectUri, ":::REDIRECTURI");
  const isMobileWebView = !window.location?.search?.includes("platform=web");
  // #4c69b9 fb color
  return (
    <>
      {isMobileWebView ? (
        // mobile app embedded webview
        <Button
          // new tab FB connect
          buttonType="outline"
          onClick={() => {
            const appId = "236977612341556";
            const scope = "public_profile";
            const url = `https://www.facebook.com/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&state=${props.state}&return_scopes=false&scope=${scope}&response_type=code&auth_type=`;
            window.open(url, "_blank");
          }}
        >
          Connect Facebook
        </Button>
      ) : (
        // webapp
        <FacebookLogin
          appId="236977612341556"
          autoLoad={true}
          fields="name,email,picture"
          // page_show_list , pages_read_engagement (once approoved)
          scope="public_profile"
          onClick={() => {}}
          buttonStyle={{
            border: "none",
            borderRadius: "4px",
            color: "white",
            fontSize: "16px",
            textTransform: "none",
            padding: 16,
          }}
          textButton="Connect Facebook"
          // Always use redirect URI, not browser popup, important for Mobile Web Views, keep these settings on desktop as well for consistency
          // disableMobileRedirect={false}
          // isMobile={true}
          // Two above props force the manual login flow: https://developers.facebook.com/docs/facebook-login/guides/advanced/manual-flow/#exchangecode
          state={props.state}
          redirectUri={redirectUri}
          // TODO: add endpoint to backend for web auth so we dont need to open a new tab
          callback={async (response) => {
            console.log(
              response,
              props.subscriptionId,
              props.subscriptionConfigToken,
              ":::RESPONSE"
            );
            // handle all tokens on the backend
            if (props.subscriptionId && props.subscriptionConfigToken) {
              await facebookLoginWebApp(
                // @ts-ignore
                response.accessToken,
                props.subscriptionId,
                props.subscriptionConfigToken
              );
              window.location.reload();
            }
          }}
        />
      )}
    </>
  );
};
