import {
  Body,
  Button,
  Input,
  Modal,
  Spacer,
  SubTitle2,
  useToasts,
  useVestaboardQueryParams,
} from "@vestaboard/installables";
import { useState } from "react";
import { useYoutubeContext } from "../hooks/useYoutubeContext";
import { Box } from  "@mui/material";
import { useDisableBodyScroll } from "../hooks/useDisableBackgroundScroll";

interface IYoutubeMoodalProps {
  onClose: () => void;
  visible: boolean;
  onSuccess: () => void;
}

export const YoutubeModal = (props: IYoutubeMoodalProps) => {
  //   const [enabled, setEnabled] = useState<boolean>(false);
  const [youtubeChannelNameTextInput, setYoutubeChannelNameTextInput] =
    useState<string>("");
  const { subscriptionId, subscriptionConfigToken } =
    useVestaboardQueryParams();
  const { onYoutubeAdded } = useYoutubeContext();
  useDisableBodyScroll(props.visible);
  const { addToast } = useToasts();

  return (
    <Modal visible={props.visible} onClose={props.onClose} fullScreenMobile>
      <Box style={{ paddingLeft: 8, paddingRight: 8 }}>
        <SubTitle2>YouTube Channel</SubTitle2>
        <Spacer size={"medium"} />
        <Body>Enter your YouTube Channel's name</Body>
        <Spacer size={"medium"} />
        <Input
          label="YouTube Channel"
          value={youtubeChannelNameTextInput}
          onValueChange={setYoutubeChannelNameTextInput}
          placeholder="@vestaboard"
        />
        <Spacer size={"medium"} />
        <Button
          buttonType="white"
          onClick={async () => {
            subscriptionId &&
              subscriptionConfigToken &&
              (await onYoutubeAdded(
                youtubeChannelNameTextInput,
                subscriptionId,
                subscriptionConfigToken
              ));
            await addToast(`YouTube channel connected`, {
              appearance: "success",
            });
            await props.onSuccess();
            props.onClose();
          }}
        >
          Set YouTube Channel
        </Button>
      </Box>
    </Modal>
  );
};
