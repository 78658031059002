import {
  Body,
  Button,
  Divider,
  Medium,
  Modal,
  Spacer,
  SubTitle2,
  useToasts,
} from "@vestaboard/installables";
import { SelectMenu } from "@vestaboard/installables/lib/components/SelectMenu";
import { useFacebookContext } from "../hooks/useFacebookContext";
import { FBButton } from "./FBButton";
import { Box, CircularProgress } from "@mui/material";
import { useDisableBodyScroll } from "../hooks/useDisableBackgroundScroll";
import { useSubscription } from "../hooks/useSubscription";
import { useOAuthState } from "../hooks/useOAuthContext";
import { useEffect } from "react";
import { DataProviders, removeDataProvider } from "../api";

interface IFacebookModalProps {
  onClose: () => void;
  visible: boolean;
  onSuccess: () => void;
}

export const FacebookModal = (props: IFacebookModalProps) => {
  const {
    pageOptions,
    selectedFacebookPageId,
    loadPages,
    setSelectedFacebookPageId,
    // instagramOptions,
    // selectedInstagramId,
    // setSelectedInstagramId,
    onSavePage,
    // loadingInstagramAccounts,
    loadingPages,
  } = useFacebookContext();
  const { addToast } = useToasts();
  useDisableBodyScroll(props.visible);
  const { subscriptionId, subscriptionConfigToken, platform } =
    useSubscription();
  const { stateParam } = useOAuthState(
    subscriptionId,
    subscriptionConfigToken,
    platform
  );
  const { visible } = props;

  useEffect(() => {
    visible && loadPages();
  }, [loadPages, visible]);

  return (
    <Modal
      visible={visible}
      onClose={async () => {
        subscriptionId &&
          subscriptionConfigToken &&
          pageOptions.length === 0 &&
          (await removeDataProvider(
            subscriptionId,
            subscriptionConfigToken,
            DataProviders.facebook
          ));
        props.onClose();
      }}
      fullScreenMobile
    >
      <Box style={{ paddingLeft: 8, paddingRight: 8 }}>
        <SubTitle2>Connect Facebook Business</SubTitle2>
        <Spacer size={"medium"} />
        {pageOptions.length === 0 && (
          <>
            <Medium>
              Authorization is required to configure Facebook data
            </Medium>
            <Spacer size={"medium"} />
            {stateParam && !loadingPages && (
              <FBButton subscriptionId={subscriptionId} subscriptionConfigToken={subscriptionConfigToken} state={stateParam} />
            )}
            <Spacer size={"medium"} />
          </>
        )}
        {pageOptions.length > 0 && (
          <>
            <Divider />
            <Spacer size={"medium"} />
            <Body>Select a Facebook page</Body>
            {loadingPages ? (
              <>
                <Spacer size={"medium"} />
                <CircularProgress />
              </>
            ) : (
              <SelectMenu
                options={pageOptions}
                onPress={setSelectedFacebookPageId}
                selected={
                  selectedFacebookPageId ? [selectedFacebookPageId] : [""]
                }
              />
            )}
            <Spacer size={"medium"} />
          </>
        )}
        {/* TODO: instagram data */}
        {/* {selectedFacebookPageId && (
          <>
            <Divider />
            <Spacer size={"medium"} />
            <Body>Select an Instagram Account (optional)</Body>
            {loadingInstagramAccounts ? (
              <>
                <Spacer size={"medium"} />
                <CircularProgress />
              </>
            ) : (
              <SelectMenu
                options={instagramOptions}
                onPress={setSelectedInstagramId}
                selected={selectedInstagramId ? [selectedInstagramId] : [""]}
              />
            )}
            {!loadingInstagramAccounts &&
              instagramOptions.length === 0 &&
              pageOptions.length > 0 && (
                <Body>
                  No Instagram accounts are connected to this Facebook business
                  page.
                </Body>
              )}
            <Spacer size={"medium"} />
          </>
        )} */}
        <Button
          disabled={!selectedFacebookPageId}
          buttonType="white"
          onClick={async () => {
            await onSavePage();
            await addToast("Facebook page connected", {
              appearance: "success",
            });
            await props.onSuccess();
            props.onClose();
          }}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
};
