import React, { useCallback, useContext, useEffect } from "react";
import { useState } from "react";
import {
  getFacebookPages,
  getInstagramAccounts,
  saveFacebookPage,
} from "../api";
import { useVestaboardQueryParams } from "@vestaboard/installables";

type IOptions = Array<{ id: string; name: string }>;

interface IFacebookContext {
  userId: string | undefined;
  setUserId: (id: string) => void;
  pageOptions: IOptions;
  setPageOptions: (pages: IOptions) => void;
  selectedFacebookPageId: string | undefined;
  setSelectedFacebookPageId: (id: string) => void;
  instagramOptions: IOptions; // a page can have multiple instagrams
  setInstagramOptions: (pages: IOptions) => void;
  selectedInstagramId: string | undefined;
  setSelectedInstagramId: (id: string) => void;
  finishedLoadingPages: boolean;
  setFinishedLoadingPages: (finished: boolean) => void;
  finishedLoadingInstagramAccounts: boolean;
  setFinishedLoadingInstagramAccounts: (finished: boolean) => void;
}
const FacebookContext = React.createContext({} as IFacebookContext);

export const FacebookProvider = (props: any) => {
  const [userId, setUserId] = useState<string>("");

  const [pageOptions, setPageOptions] = useState<IOptions>([]);
  const [selectedFacebookPageId, setSelectedFacebookPageId] = useState<
    string | undefined
  >();

  const [instagramOptions, setInstagramOptions] = useState<IOptions>([]);
  const [selectedInstagramId, setSelectedInstagramId] = useState<
    string | undefined
  >();

  const [finishedLoadingPages, setFinishedLoadingPages] =
    useState<boolean>(false);
  const [
    finishedLoadingInstagramAccounts,
    setFinishedLoadingInstagramAccounts,
  ] = useState<boolean>(false);

  return (
    <FacebookContext.Provider
      value={{
        userId,
        setUserId,
        pageOptions,
        setPageOptions,
        selectedFacebookPageId,
        setSelectedFacebookPageId,
        instagramOptions,
        setInstagramOptions,
        selectedInstagramId,
        setSelectedInstagramId,
        finishedLoadingPages,
        setFinishedLoadingPages,
        finishedLoadingInstagramAccounts,
        setFinishedLoadingInstagramAccounts,
      }}
    >
      {props.children}
    </FacebookContext.Provider>
  );
};

export const useFacebookContext = () => {
  const {
    userId,
    setUserId,
    pageOptions,
    setPageOptions,
    selectedFacebookPageId,
    setSelectedFacebookPageId,
    instagramOptions,
    setInstagramOptions,
    selectedInstagramId,
    setSelectedInstagramId,
    finishedLoadingPages,
    setFinishedLoadingPages,
    finishedLoadingInstagramAccounts,
    setFinishedLoadingInstagramAccounts,
  } = useContext(FacebookContext);

  const { subscriptionId, subscriptionConfigToken } =
    useVestaboardQueryParams();

  const loadPages = useCallback(async () => {
    if (subscriptionId && subscriptionConfigToken) {
      try {
        const { pages, userId } = await getFacebookPages(
          subscriptionId,
          subscriptionConfigToken
        );
        setUserId(userId);
        setPageOptions(pages);
        setFinishedLoadingPages(true);
      } catch (e) {
        setFinishedLoadingPages(true);
      }
    }
  }, [
    setUserId,
    setPageOptions,
    setFinishedLoadingPages,
    subscriptionId,
    subscriptionConfigToken,
  ]);

  useEffect(() => {
    setFinishedLoadingInstagramAccounts(false);
    selectedFacebookPageId &&
      subscriptionId &&
      subscriptionConfigToken &&
      getInstagramAccounts(
        subscriptionId,
        subscriptionConfigToken,
        selectedFacebookPageId
      ).then((accounts) => {
        setSelectedInstagramId("");
        setInstagramOptions(
          accounts.map((account) => ({
            id: account.id,
            name: account.name,
          }))
        );
        setFinishedLoadingInstagramAccounts(true);
      });
  }, [
    selectedFacebookPageId,
    subscriptionId,
    subscriptionConfigToken,
    setInstagramOptions,
    setSelectedInstagramId,
    setFinishedLoadingInstagramAccounts,
  ]);

  const onSavePage = async () => {
    subscriptionId &&
      subscriptionConfigToken &&
      selectedFacebookPageId &&
      (await saveFacebookPage(
        subscriptionId,
        subscriptionConfigToken,
        selectedFacebookPageId,
        selectedInstagramId || ""
      ));
  };

  return {
    userId,
    pageOptions,
    loadPages,
    selectedFacebookPageId,
    setSelectedFacebookPageId,
    instagramOptions,
    setInstagramOptions,
    selectedInstagramId,
    setSelectedInstagramId,
    onSavePage,
    loadingPages: !finishedLoadingPages,
    loadingInstagramAccounts:
      selectedFacebookPageId && !finishedLoadingInstagramAccounts,
  };
};
