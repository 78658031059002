import { VestaboardContextProvider } from "@vestaboard/installables/lib/hooks";
import { Settings } from "./pages/Settings";
import { Theme } from "@vestaboard/installables/lib/components/Theme";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastProvider } from "@vestaboard/installables";
import { FacebookProvider } from "./hooks/useFacebookContext";
import { YoutubeProvider } from "./hooks/useYoutubeContext";
import { TwitchProvider } from "./hooks/useTwitchContext";
import { FacebookOAuth } from "./pages/FacebookOAuth";
import { OAuthStateProvider } from "./hooks/useOAuthContext";
import { PatreonProvider } from "./hooks/usePatreonContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Settings />,
  },
  {
    path: "/settings/:singledataprovider",
    element: <Settings />,
  },
  {
    path: "/facebook",
    element: <FacebookOAuth />,
  },
]);

const App = () => {
  return (
    <VestaboardContextProvider>
      <Theme>
        <OAuthStateProvider>
          <FacebookProvider>
            <YoutubeProvider>
              <TwitchProvider>
                <PatreonProvider>
                  <ToastProvider offset={0}>
                    <RouterProvider router={router} />
                  </ToastProvider>
                </PatreonProvider>
              </TwitchProvider>
            </YoutubeProvider>
          </FacebookProvider>
        </OAuthStateProvider>
      </Theme>
    </VestaboardContextProvider>
  );
};

export default App;
